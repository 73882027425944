import React, { useEffect, useState } from 'react';
import { 
  DataGrid, 
  GridToolbarContainer, 
  GridToolbarExport, 
  gridClasses } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import env from '../../config';
import axios from 'axios';
import './Projetos.scss';
import Visualizar from './Visualizar.js';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { Button } from '@mui/material';

dayjs.locale('pt-br'); // Configuração do idioma

const ProjetosList = () => {
  const [objects, setObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = React.useState(100);
  const entity = '/listarProjetosFiltrados';
  const [selectedDate, setSelectedDate] = useState();
  const backendUrl = env.backendUrl;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [loadingProjects, setLoadingProjects] = useState([]);

  const handleDateChange = (date) => {
    // date = date.$d;
    setSelectedDate(date);

    var mes;
    var ano;

    // if(date.$d){
      mes = date.$d.getMonth() + 1;
      ano = date.$d.getFullYear();
    // }else{
    //   mes = date.getMonth() + 1; // Adiciona 1 porque os meses são indexados de 0 a 11
    //   ano = date.getFullYear();
    // }

    fetchObjects(mes, ano);
  };

  useEffect(() => {
    // const hoje = new Date();
    // const mesAtual = hoje.getMonth() + 1; // Adiciona 1 porque os meses são indexados de 0 a 11
    // const anoAtual = hoje.getFullYear();
    // fetchObjects(mesAtual, anoAtual);

    handleDateChange(dayjs(new Date()));

  }, []);

  const fetchObjects = (mes, ano) => {
    const url = backendUrl + entity + "/" + mes + "/" + ano;

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setObjects(objects)
    })  
    .catch(err => {
      console.error(err);
    });  
  }

  // const addObject = (object) => {
    // const url = env.backendUrl + entity;
    // const data = JSON.stringify(object);

    // const config = {
    //   headers: { 'Content-Type':'application/json' }
    // };

    // axios.post(url, data, config)
    // .then(response => {
    //   if (response.status === 200) {
    //     fetchObjects(obterMesSelecionado(), obterAnoSelecionado());
    //   }
    //   else {
    //     alert('Something went wrong!');
    //   }
    // })
    // .catch(err => console.error(err))
  // }

  // Update object 
  const updateObject = (object, link) => {
    // const url = env.backendUrl  +  entity + '/' + link;
    // const data = JSON.stringify(object);
    // const config = {
    //   headers: { 'Content-Type':'application/json' }
    // };

    // axios.put(url, data, config)
    // .then(response => {
    //   if (response.status === 200) {
    //     fetchObjects(obterMesSelecionado(), obterAnoSelecionado());
    //   }
    //   else {
    //     alert('Something went wrong!');
    //   }
    // })
    // .catch(err => console.error(err))
  }

  const obterInvoiceNumber = (projeto_id) => {
    var mes = obterMesSelecionado();
    var dia = "01";
    var ano = selectedDate ? selectedDate.format('YYYY') : '';

    var invoiceNumber = mes + dia + ano + "-" + projeto_id;
    return invoiceNumber;
  }

  const obterMesSelecionado = () => {
    var mes = selectedDate ? selectedDate.format('MM') : '';
    return mes;
  }

  const obterMesSelecionadoSemZeros = () => {
    var mes = selectedDate ? selectedDate.format('M') : '';
    return mes;
  }

  const obterMesAbreviadoSelecionado = () => { //jun, set, oct
    var mes = selectedDate ? selectedDate.format('MMM') : '';
    return mes;
  }

  const obterAnoSelecionado = () => {
    var ano = selectedDate ? selectedDate.format('YYYY') : '';
    return ano;
  }

  const handleButtonClick = (row) => {
    var projeto_id = row.id

    var invoiceNumber = obterInvoiceNumber(projeto_id);
    
    // Aqui você pode chamar sua API externa usando fetch, axios, etc.
    const url = backendUrl + "/marcarComoPago/" + projeto_id + "/" +invoiceNumber;

    axios.get(url)
    .then(res => {
      //console.info(res.data);
      fetchObjects(obterMesSelecionado(), obterAnoSelecionado());
    })  
    .catch(err => {
      console.error(err);
    });  
  };

  const handleButtonClickEnviar = (row) => {
    var projeto_id = row.id

    var mes = selectedDate.$d.getMonth() + 1

    setLoadingProjects(prevLoadingProjects => [...prevLoadingProjects, projeto_id]);
    
    // Aqui você pode chamar sua API externa usando fetch, axios, etc.
    const url = backendUrl + "/enviar/" + projeto_id + "/" +mes;

    axios.get(url)
    .then(res => {
      //console.info(res.data);
      fetchObjects(obterMesSelecionado(), obterAnoSelecionado());
    })  
    .catch(err => {
      console.error(err);
    })
    .finally(() => {
      // Atualize o estado após a conclusão da chamada da API, seja com sucesso ou erro
      setLoadingProjects(prevLoadingProjects =>
        prevLoadingProjects.filter(projectId => projectId !== projeto_id)
      );
    });
  };

  const deveDesabilitarBotaoReceber = (row) => {
    const projetoId = row.id; 

    const recebido = objects.some(projeto => 
      projeto.id === projetoId &&
      projeto.recebidos &&
      projeto.recebidos.some(recebido => 
        recebido.projeto_id === projetoId &&
        recebido.invoice_number === obterInvoiceNumber(projetoId)
      )
    );

    const enviado = deveDesabilitarBotaoEnviar(row);

    return recebido || !enviado;
  };

  const deveDesabilitarBotaoEnviar = (row) => {
    const projetoId = row.id; 

    const enviado = objects.some(obj => 
      obj.id === projetoId &&
      obj.envios.some(envio => {
        return (
          envio.invoice_number === obterInvoiceNumber(projetoId) //&&
        );
      })
    );

    const recebido = objects.some(projeto => 
      projeto.id === projetoId &&
      projeto.recebidos &&
      projeto.recebidos.some(recebido => 
        recebido.projeto_id === projetoId &&
        recebido.invoice_number === obterInvoiceNumber(projetoId)
      )
    );

    const naoEnviar = objects.some(projeto => 
      projeto.id === projetoId && projeto.enviar_invoice === 0
    );


    return enviado || recebido || naoEnviar;
  };

  const totalizarValorDoProjeto = (params) => {
    let total = 0;
    let servicos = params.row.servicos;

    for(let i = 0; i < servicos.length; i++ ){
      let servico = params.row.servicos[i];
      let mes = obterMesSelecionadoSemZeros();
      let estaEmFall = eval('servico.mes_' + mes);
      let mes_abreviado = obterMesAbreviadoSelecionado().toLowerCase();
      
      if (servico.ocultar !== "1") {
        if( estaEmFall === 'fall' && dentroDoMesVigente(servico.rota_tipo, servico.frequencia_quando_fall, mes_abreviado) ){
          total += servico.valor_quando_fall;
        }else if( dentroDoMesVigente(servico.rota_tipo, servico.frequencia, mes_abreviado) ){
          total += servico.valor;
        }
      }
    }
    
    return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  function dentroDoMesVigente(rota_tipo, frequencia, mes_abreviado){
    if( rota_tipo === 'trimestre' ){
      let frequencia_lower = frequencia.toLowerCase();

      return frequencia_lower.includes(mes_abreviado); // ONCE A WEEK - Thursday includes (thu)?
    }

    return true;
  }

  const abrirPDF = (clienteID) => {
    // URL do PDF que você deseja abrir
    const pdfUrl = 'assets/contratos/'+ clienteID + '.pdf';
    
    // Abre o PDF em uma nova aba
    window.open(pdfUrl, '_blank');
  };

  const columns = [
    { field: 'n', headerName: 'N', width: 30, 
      renderCell: (params) => params.row.id,
    },
    { field: 'projeto', headerName: 'Project', width: 200,
      renderCell: (params) => params.row.titulo
    },
    { field: 'servico', headerName: 'Customer', width: 200,
      renderCell: (params) => params.row.cliente.nome_da_empresa
    },
    { field: 'valor', headerName: 'Amount', width: 200,
      renderCell: (params) => totalizarValorDoProjeto(params)
    },
    {
      field: 'contrato', 
      headerName: 'Contract', 
      sortable: false,
      filterable: false,
      renderCell: (params) => { 
        if(params.row.contrato){
          return <Button 
            variant="contained" 
            data={params} 
            onClick={() => abrirPDF(params.row.contrato)}>Open</Button>
        }else{
          return ''
        }
      }
    }, 
    {
      field: 'visualizar', 
      headerName: 'View', 
      sortable: false,
      filterable: false,
      renderCell: row => 
        <Visualizar
          data={[row, selectedDate.$d.getMonth() + 1]} 
          updateObject={updateObject} />
    },
    {
      field: 'Enviar', 
      headerName: 'Send', 
      sortable: false,
      filterable: false,
      hide: (user.tipo !== 'a'),
      renderCell: (params) => { 

        if(loadingProjects.includes(params.row.projeto_id) ){
          return <img src='../assets/img/loading-25.gif' width={25} style={{margin: "0 auto"}} alt='loading'/>
        }else{
          return (  
            <Button variant="contained" 
            // width="200"
            color="primary" 
            onClick={() => handleButtonClickEnviar(params.row)}
            disabled={deveDesabilitarBotaoEnviar(params.row)}  >
                SEND
            </Button>
            )
        }
      },
    },
    {
      field: 'Receber', 
      headerName: 'Receive', 
      sortable: false,
      filterable: false,
      hide: (user.tipo !== 'a'),
      renderCell: (params) => ( 
        <Button variant="contained" 
        color="primary" 
        disabled={deveDesabilitarBotaoReceber(params.row)}  
        onClick={() => handleButtonClick(params.row)}>
          Receive
        </Button>
      ),
    },
    { 
      field: 'statusEnvio', 
      headerName: 'Status', 
      width: 150,
      renderCell: (params) => {
        const projetoId = params.row.id;
        // const hoje = new Date();
        
        const enviado = objects.some(obj => 
          obj.id === projetoId &&
          obj.envios.some(envio => {
            // const dataEnvio = new Date(envio.envio);
            return (
              envio.invoice_number === obterInvoiceNumber(projetoId) //&&
              // dataEnvio.getDate() === hoje.getDate() &&
              // dataEnvio.getMonth() === hoje.getMonth() &&
              // dataEnvio.getFullYear() === hoje.getFullYear()
            );
          })
        );

        const recebido = objects.some(projeto => 
          projeto.id === projetoId &&
          projeto.recebidos &&
          projeto.recebidos.some(recebido => 
            recebido.projeto_id === projetoId &&
            recebido.invoice_number === obterInvoiceNumber(projetoId)
          )
        );

        const naoEnviar = objects.some(projeto => 
          projeto.id === projetoId && projeto.enviar_invoice === 0
        );

        if(recebido){
          return "received";
        }else if(naoEnviar){
          return "don't send";
        }else if(enviado){
          return "sent";
        }else{
          return "send"
        }
      
      }
    }
  ];

  return  (
    <div id="objects" className="container">
      <h1>Projects</h1>
      <Stack direction="row" justifyContent="space-between" mt={2} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-BR">
          <DemoContainer components={['DatePicker']}>
            <DatePicker 
            views={['year', 'month']}
            label="Choose a date" 
            onAccept={handleDateChange}
            // defaultValue={dayjs(new Date())}
            />
          </DemoContainer>
        </LocalizationProvider>
        {/* {selectedDate 
        && 
        (
          <>
            {selectedDate.format('MM')}/
            {selectedDate.format('DD')}/
            {selectedDate.format('YYYY')}
          </>
        )
        } */}
      </Stack>
      {objects.length > 0 ? (
      <DataGrid 
        // autoHeight 
        loading={objects.length <= 0}
        rows={objects} 
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20, 100]}
        components={{ Toolbar: CustomToolbar }}/>
        ) : (
          <p>No data</p>
        )}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Item deleted"
      />
    </div>
)};

function CustomToolbar() {
  return (
    <GridToolbarContainer 
      className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default ProjetosList; 