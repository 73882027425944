import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
// import mitt from 'mitt';
import env from '../config.js';
import emitter from './eventEmitter.js';

import './Login.scss';

function Login() {
  const [user, setUser] = useState({
    email: '', 
    password: ''
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  
  const handleChange = (event) => {
    setUser({...user, [event.target.name] : event.target.value});
  }

  const backendUrl = env.backendUrl;
  
  const login = () => {
    const url = backendUrl + '/auth/signin';

    const data = user;

    const config = {
      headers: { 'Content-Type':'application/json' }
    };
    
    axios.post(url, data, config)
    .then(res => {
      const jwtToken = res.data.access_token;

      if (jwtToken !== null && jwtToken !== undefined) {
        sessionStorage.setItem("jwt", jwtToken);
        sessionStorage.setItem("isAuthenticated", true);
        
        if(res.data.user){
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          // var result = Object.keys(res.data.user).map((key) => [key, res.data.user[key]]);
          emitter.emit('eventoPersonalizado', "login");
          console.info("login feito com sucesso...");
          
          navigate("/home");
        }
      }
      else {
        setOpen(true);
      }
    }).catch(function (error) {
      setOpen(true);
    });



    // axios.post(envbackendUrl + '/signin', {
    //   method: 'POST',
    //   headers: { 'Content-Type':'application/json' },
    //   body: JSON.stringify(user)
    // })
    // .then(response => {
    //   debugger;
    //   response.json()
    // } )
    // .then(res => {
    //   const jwtToken = res.token;

    //   if (jwtToken !== null && jwtToken !== undefined) {
    //     sessionStorage.setItem("jwt", jwtToken);
    //     sessionStorage.setItem("isAuthenticated", true);
    //     navigate("/home");
    //   }
    //   else {
    //     setOpen(true);
    //   }
    // })
    // .catch(err => {
    //   //console.error(err)
    // })
  }

    return(
      <>
      <div className="login-area space__bottom--r120">
        <div className="container">
            <div className="row">
                <div id="login-form" className="col-sm-12 col-md-12 col-xs-12 col-lg-6">
                    <form name="formX">
                        <h1 className="">Signin</h1>
                        <div className="login-form">
                            <div className="row">
                                <div className="col-md-12 col-12 space__bottom--20">
                                    <label>Email</label>
                                    <input className="mb-0" name="email" type="text" placeholder="Email" onChange={handleChange}/>
                                </div>
                                <div className="col-12 space__bottom--20">
                                    <label>Password</label>
                                    <input className="mb-0" name="password" type="password" placeholder="Password" onChange={handleChange}/>
                                </div>
                                <div className="col-md-12">
                                    <button type="button" className="default-btn mt-0" id="entrar" onClick={login}>Enter</button>
                                </div>
                                <p className="versao">v1.0.0</p>
                            </div>
                        </div>
                    </form>
                    <Snackbar 
                      open={open}
                      autoHideDuration={3000}
                      onClose={() => setOpen(false)}
                      message="Login failed: Check your username and password"
                    />
                </div>
            </div>
        </div>
    </div>
    </>
    );
  
}

export default Login;
