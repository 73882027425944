import { Outlet, Link } from "react-router-dom";
import React from 'react';
import './Layout.scss';
import '../assets/scss/style.scss';
import emitter from './eventEmitter';

const Layout = () => {
    const [receivedData, setReceivedData] = React.useState('');

    emitter.on('eventoPersonalizado', data => {
        console.info(data);
        let user2 = JSON.parse(sessionStorage.getItem("user"))
        setReceivedData(user2.name); 
    });

    function sair(){
        console.info("saindo...");

        sessionStorage.removeItem("user");

        window.history.replaceState({}, '', '/');
        window.location.reload();
    }

    return (
    <>
        <div id="layout" className="header-area header-sticky bg-img space__inner--y40 background-repeat--x d-none d-lg-block" >
        {/* <!-- header top --> */}
        <div className="header-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="header-top-info">
                            <span className="header-top-info__image pr-1"><img src="assets/img/icons/phone.png" alt="" /></span>
                            {/* <span className="header-top-info__text">(408) 469-3124</span> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="header-top-info text-center">
                            {/* <!-- <span className="header-top-info__image pr-1"><img src="assets/img/icons/clock.png" alt=""></span> --> */}
                            {/* <!-- <span className="header-top-info__text">07.00 - 21.00</span> --> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {receivedData && <div className="header-top-info text-right">
                            <span>Welcome: {receivedData}</span>
                            &nbsp;
                            <Link className="header-top-info__link btn-sair" onClick={sair}><span>SAIR</span></Link>
                        </div>}
                        {receivedData == null && 
                            <Link className="header-top-info__link btn-sair" onClick={sair}><span>LOGIN</span></Link>
                        }
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- menu bar --> */}
        <div className="menu-bar position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="menu-bar-wrapper background-color--default space__inner--x35">
                            <div className="menu-bar-wrapper-inner">
                                <div className="row align-items-center">
                                    <div className="col-lg-2">
                                        <div className="brand-logo">
                                            <a href="/">
                                                <img src="../assets/img/logo-2.webp" className="img-fluid" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="navigation-area d-flex justify-content-end align-items-center">
                                            {/* <!-- navigation menu --> */}
                                            <nav className="main-nav-menu">
                                            {receivedData && 
                                                <ul className="d-flex justify-content-end">
                                                    <li>
                                                        <Link to="/home">Home</Link>
                                                    </li>
                                                    
                                                    <li>
                                                        <Link to="/clientes">Customers</Link>
                                                    </li>
                                                    {/*<li>
                                                        <Link to="/services">Services</Link>
                                                    </li> */}
                                                    <li>
                                                        <Link to="/projetos">Projects</Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/routes">Routes</Link>
                                                    </li>
                                                    
                                                </ul>
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* <!--====================  mobile header ====================--> */}
      <div className="mobile-header header-sticky bg-img space__inner--y30 background-repeat--x d-block d-lg-none" >
        <div className="container">
            <div className="row align-items-center">
                <div className="col-6">
                    <div className="brand-logo">
                        <a href="/">
                            <img src="../assets/img/logo-2.webp" className="img-fluid" alt="" />
                        </a>
                    </div>
                </div>
                <div className="col-6">
                    <div className="mobile-menu-trigger-wrapper text-right" id="mobile-menu-trigger">
                        <span className="mobile-menu-trigger"></span>
                        <span className="alerta"></span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* // <!--====================  End of mobile header  ====================--> */}
      {/* // <!--====================  offcanvas mobile menu ====================--> */}
      <div className="offcanvas-mobile-menu" id="mobile-menu-overlay">
        <button className="offcanvas-menu-close" id="mobile-menu-close-trigger">
            <span className="menu-close"></span>
        </button>
        <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
                <div className="offcanvas-mobile-search-area">
                    
                </div>
                <nav className="offcanvas-navigation">
                {receivedData && 
                    <ul>
                        <li>
                            <Link to="/home">Home</Link>
                        </li>
                        <li>
                            <Link to="/clientes">Customers</Link>
                        </li>
                        {/*<li>
                            <Link to="/services">Services</Link>
                        </li> */}
                        <li>
                            <Link to="/projetos">Projects</Link>
                        </li>
                        <li>
                            <Link to="/routes">Routes</Link>
                        </li>
                    </ul>
                }
                </nav>
                <div className="offcanvas-widget-area">
                    <div className="off-canvas-contact-widget">
                        <div className="header-contact-info">
                            <ul className="header-contact-info__list">
                                <li><i className="fa fa-phone"></i> (408) 469-3124</li>
                                {/* <a className="header-top-info__link" ><span>LOGIN</span></a>
                                <a className="header-top-info__link btn-sair" ><span>SAIR</span></a> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* <!--====================  End of offcanvas mobile menu  ====================--> */}
{/* <!--====================  End of header area  ====================--> */}
      <div className="wrapper">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </>
  )
};

export default Layout;