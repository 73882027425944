import React, { useEffect, useState } from 'react';
import { 
  DataGrid, 
  GridToolbarContainer, 
  GridToolbarExport, 
  gridClasses } from '@mui/x-data-grid';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import env from '../../config';
// import Add from './Add.js';
// import Edit from './Edit.js';
import axios from 'axios';

import './Customers.scss';

const Customers = () => {
  const [objects, setObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const entity = '/clientes';
  const backendUrl = env.backendUrl;

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = () => {    
    const url = backendUrl + entity;

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setObjects(objects)
    })  
    .catch(err => {
      console.error(err);
    });
  }
/* 
  const onDelClick = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      const url = backendUrl + entity + '/' + id;

      axios.delete(url)
      .then(response => { 
        if (response.status === 200) {
          setOpen(true);
          fetchObjects();
        }
        else {
          alert('Something went wrong!');
        }  
      })
      .catch(err => {
        console.error(err);
      });
    }
  } */
/* 
  const addObject = (object) => {

    const url = backendUrl + entity;
    const data = JSON.stringify(object);

    const config = {
      headers: { 'Content-Type':'application/json' }
    };

    axios.post(url, data, config)
    .then(response => {
      if (response.status === 200) {
        fetchObjects();
      }
      else {
        alert('Something went wrong!');
      }
    })
    .catch(err => console.error(err))
  }
 */
/* 
  const update = (object, link) => {
    const url = backendUrl  +  entity + '/' + link;

    const data = JSON.stringify(object)

    const config = {
      headers: { 'Content-Type':'application/json' }
    };

    axios.put(url, data, config)
    .then(response => {
      if (response.status === 200) {
        fetchObjects();
      }
      else {
        alert('Something went wrong!');
      }
    })
    .catch(err => console.error(err))
  }
 */

  const columns = [
    { field: 'id', headerName: 'id', width: 55 },
    { field: 'nome_da_empresa', headerName: 'Company', width: 300 },
    // { field: 'email', headerName: 'Email', width: 250 },
    { field: 'endereco_cidade', headerName: 'City', width: 150 },
    { field: 'endereco_estado', headerName: 'State', width: 100 }, 
    { field: 'email', headerName: 'Email', width: 250 }, 
    { field: 'telefone', headerName: 'Phone', width: 250 }, 
    // {
    //   field: 'id', 
    //   headerName: 'Edit', 
    //   sortable: false,
    //   filterable: false,
    //   renderCell: row => 
    //     <Edit
    //       data={row} 
    //       update={update} />
    // },  
    // {
    //   field: 'id2', 
    //   headerName: 'Delete', 
    //   sortable: false,
    //   filterable: false,
    //   renderCell: row => 
    //     <IconButton onClick={() => onDelClick(row.id)}>
    //       <DeleteIcon color="error" />
    //     </IconButton>
    // }  
  ];

  return  (
    <div id="customers" className="container">
      <h1>Customers</h1>
      {/* <Stack mt={2} mb={2}>
        <Add addObject={addObject} />
      </Stack> */}
      <DataGrid 
        // autoHeight 
        loading={objects.length <= 0}
        rows={objects} 
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        components={{ Toolbar: CustomToolbar }}/>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Customer deleted"
      />
    </div>
)};

function CustomToolbar() {
  return (
    <GridToolbarContainer 
      className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default Customers;