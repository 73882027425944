import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import env from '../../config';
import axios from 'axios';


function Add(props) { 
  const initialState = {
    date: new Date(),
    value: '',
    description: '',
    customer_id: '',
    service_id: ''
  };

  const [open, setOpen] = useState(false);
  const [object, setObject] = useState(initialState);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const backendUrl = env.backendUrl;

  // Open the modal form
  const handleClickOpen = () => {
    setOpen(true);
  };
    
  // Close the modal form 
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setObject({...object, [event.target.name]: event.target.value});
  }

  // Save object and close modal form 
  const handleSave = () => {
    props.addObject(object);
    setObject(initialState);
    handleClose();
  }

  const fetchCustomers = () => {    
    const url = backendUrl + "/clientes";

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setCustomers(objects)
    })  
    .catch(err => {
      console.error(err);
    });
  }

  const fetchServices = () => {    
    const url = backendUrl + "/services";

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setServices(objects)
    })  
    .catch(err => {
      console.error(err);
    });
  }

  const isDisabled = () => {
    if(object.value === '' 
    || object.description === '' 
    || object.service_id === '' 
    || object.customer_id === '' ){
      return true;
    }

    return false;
  }

  useEffect(() => {
    fetchCustomers();
    fetchServices();
  }, []);
  
  return(
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        New 
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Item</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                  label="Date" 
                  name="date" 
                  format='YYYY-DD-MM'
                  value={dayjs(object.date)} 
                  onChange={(value) => {
                    let i = dayjs(value.$d).format('YYYY-MM-DD');

                    const newState = Object.assign({}, object);
                    newState.date = i;
                    setObject(newState);
                    
                    console.info(object);
                  }}
                  // defaultValue={dayjs('2022-04-17')} 
                  variant="standard"/>
              </LocalizationProvider>
              <TextField label="Value" name="value" type="number"
                variant="standard" value={object.value} 
                onChange={handleChange}/>
              <TextField label="Description" name="description" 
                multiline
                // maxRows={4}
                variant="standard" value={object.description} 
                onChange={handleChange}/>
              <TextField 
                label="service" 
                select
                name="service_id" 
                variant="standard" value={object.service_id} 
                onChange={handleChange}>
                {services.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
              <TextField 
                select
                label="customer" 
                name="customer_id" 
                variant="standard" 
                value={object.customer_id} 
                onChange={handleChange}>
                  {customers.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.company}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isDisabled()} onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>            
    </div>
  );
}

export default Add;
