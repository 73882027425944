import axios from 'axios';


// // Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response 1<-");
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("response 2 <-");
    //TODO: tratar erro quando for de token expirado
    if(error.response && error.response.data.message === "Token has expired"){
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("jwt");
        sessionStorage.removeItem("isAuthenticated");
        window.history.replaceState({}, '', '/login');
        window.location.reload();
    }

    // window.history.replaceState({}, '', '/login');
    // window.location.reload();

    return Promise.reject(error);
});

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("jwt"); 
    config.headers.Authorization =  "Bearer " + token;
    return config;
}, function (error) {
    return Promise.reject(error);
});