import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import './Invoice.scss';

const Invoice = () => {

  const rows = [
    { id: 1, col1: '1', col2: '', col3: 'Power Sweeping1', col4: '', col5: '1', col6: '', col7: '1350' },
    { id: 2, col1: '2', col2: '', col3: 'Power Sweeping2', col4: '', col5: '2', col6: '', col7: '1350' },
    { id: 3, col1: '3', col2: '', col3: 'Power Sweeping3', col4: '', col5: '3', col6: '', col7: '1350' },
  ];

  const columns = [
    { field: 'col1', headerName: '#', width: 10 },
    { field: 'col2', headerName: 'Date', width: 150 },
    { field: 'col3', headerName: 'Product or service', width: 250 },
    { field: 'col4', headerName: 'SKU', width: 150 },
    { field: 'col5', headerName: 'Qty', width: 150, type: 'number' },
    { field: 'col6', headerName: 'Rate', width: 150 },
    { field: 'col7', headerName: 'Amount', width: 150}
  ];

    // Calculate summary values
    const totalAmount = rows.reduce((sum, row) => sum + Number(row.col7.replace(/\$/, '').replace(/,/, '')), 0);

      // Define the summary row
  const summaryRow = {
    id: 'summary',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: 'Total',
    col7: `${totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
  };

  return  (
    <div className="container">
      <h1>Invoice</h1>
      <div className='row align-items-center'>
        <div className='col'>
          <p>AMSWEEPER INC</p>
          <p>PO Box 12194</p>
          <p>Pleasanton. CA 94588</p>
        </div>
        <div className='col'>
          <p>amsweeper@gmail.com</p>
          <p>+1 (925) 460-7600</p>
          <p>www.amsweeper.com</p>
        </div>
        <div className='col'>
          <img src='../assets/img/logo-2.webp' alt='logo' width="200"/>
        </div>
      </div>
      <p>01 - HCP Oyster Point III LLC c/o CBRE</p>
      <div id='to' className='row'>
        <div className='col'>
            <p>Bill to</p>
            <p>01 - HCP Oyster Point III LLC c/o CBRE</p>
            <p>8:6099-54901-2614</p>
            <p>PO Box 2090</p>
            <p>Warren MI 18090-2090</p>
          </div>
          <div className='col'>
            <p>Ship to</p>
            <p>01 - HCP Oyster Point III LLC c/o CBRE</p>
            <p>8:6099-54901-2614</p>
            <p>PO Box 2090</p>
            <p>Warren MI 18090-2090</p>
          </div>
      </div>
      <div id='details' className='row'>
        <div className='col'>
          <p>Invoice details</p>
          <p>Invoice no. 129197/01</p>
          <p>Invoice date 12/01/2023</p>
        </div>
      </div>
      <div id='table' className='row'>
        <div style={{ height: 300, width: '100%' }}>
          <DataGrid 
          sx={{
            border: 0,
            borderColor: 'primary.light',
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            },
            '& .MuiDataGrid-row:last-child': {
              borderBottom: 'solid 4px white',
            },
            '& .MuiDataGrid-cell  ': {
              borderBottom: 'solid 0px red',
            },
          }}
            rows={rows.concat([summaryRow])}
            columns={columns} 
            hideFooterPagination
            hideFooterSelectedRowCount
            
            components={{
              noRowsOverlay: () => "No rows found", // Optional overlay for empty table
              Toolbar: () => null, // Optional to hide the toolbar
              Footer: () => null, // Optional to hide the footer
              ColumnHeader: () => null, // Optional to hide column headers
              GridToolbar: () => null, // Optional to hide grid toolbar
            }}
            componentsProps={{
              toolbar: { container: null }, // Optional to hide toolbar container
            }}
            />
        </div>
      </div>
    </div>
)};

export default Invoice; 