import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LayoutPrint from "./components/LayoutPrint";
import Customer from "./components/customer/Customers";
import ServiceList from "./components/service/Service";
import MyRoutes from "./components/routes/MyRoutes";
import Invoices from "./components/invoices/Invoices";
import Projetos from "./components/projetos/Projetos";
import NoPage from "./components/NoPage";
import Login from "./components/Login";
import './index.css';
import Home from "./components/home/Home";
import './components/Interceptor2.js';
import Invoice from "./components/invoices/Invoice.js";
export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="clientes" element={<Customer />} />
          <Route path="services" element={<ServiceList />} />
          <Route path="Routes" element={<MyRoutes />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="projetos" element={<Projetos />} />
          <Route path="home" element={<Home />} />
          <Route path="*" element={<NoPage />} />
          <Route path="print" element={<Home />} />
        </Route>
        <Route path="/print" element={<LayoutPrint />}>
          <Route index element={<Invoice />} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);