import React, { useEffect, useState } from 'react';
import { 
  DataGrid, 
  GridToolbarContainer, 
  GridToolbarExport, 
  gridClasses } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import env from '../../config';
import Add from './Add.js';
import Edit from './Edit.js';
import axios from 'axios';
import './Invoices.scss';

const InvoicesList = () => {
  const [objects, setObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const entity = '/invoices';
  const backendUrl = env.backendUrl;

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = () => {
    const url = backendUrl + entity;

    axios.get(url)
    .then(res => {
      const objects = res.data;
      setObjects(objects)
    })  
    .catch(err => {
      console.error(err);
    });  
  }


  const onDelClick = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      const url = backendUrl + entity + '/' + id;

      axios.delete(url)
      .then(response => { 
        if (response.status === 200) {
          setOpen(true);
          fetchObjects();
        }
        else {
          alert('Something went wrong!');
        }  
      })
      .catch(err => {
        console.error(err);
      });
    }
  }

  const addObject = (object) => {
    const url = backendUrl + entity;
    const data = JSON.stringify(object);

    const config = {
      headers: { 'Content-Type':'application/json' }
    };

    axios.post(url, data, config)
    .then(response => {
      if (response.status === 200) {
        fetchObjects();
      }
      else {
        alert('Something went wrong!');
      }
    })
    .catch(err => console.error(err))
  }

  // Update object 
  const updateObject = (object, link) => {
    const url = backendUrl  +  entity + '/' + link;
    const data = JSON.stringify(object);
    const config = {
      headers: { 'Content-Type':'application/json' }
    };

    axios.put(url, data, config)
    .then(response => {
      if (response.status === 200) {
        fetchObjects();
      }
      else {
        alert('Something went wrong!');
      }
    })
    .catch(err => console.error(err))
  }

const columns = [
  { field: 'date', headerName: 'Date', width: 200,
    renderCell: row => 
    // dayjs(row.date).format('MM-DD-YYYY')
    row.date
  },
  { field: 'value', headerName: 'Value', width: 200,
    renderCell: row => 
    "$ " + row.value 
  },
  { field: 'description', headerName: 'Description', width: 200 },
  {
    field: 'id', 
    headerName: 'Edit', 
    sortable: false,
    filterable: false,
    renderCell: row => 
      <Edit
        data={row} 
        updateObject={updateObject} />
  },  
  {
    field: 'id2', 
    headerName: 'Delete', 
    sortable: false,
    filterable: false,
    renderCell: row => 
      <IconButton onClick={() => onDelClick(row.id)}>
        <DeleteIcon color="error" />
      </IconButton>
  }  
];

  return  (
    <div id="objects" className="container">
      <h1>Invoices</h1>
      <Stack mt={2} mb={2}>
        <Add addObject={addObject} />
      </Stack>
      <DataGrid 
        // autoHeight 
        loading={objects.length <= 0}
        rows={objects} 
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        components={{ Toolbar: CustomToolbar }}/>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        message="Item deleted"
      />
    </div>
)};

function CustomToolbar() {
  return (
    <GridToolbarContainer 
      className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default InvoicesList; 