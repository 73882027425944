import { Outlet } from "react-router-dom";
import React from 'react';
import './Layout.scss';
import '../assets/scss/style.scss';

const LayoutPrint = () => {


    return (
    <>
      <div className="wrapper">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </>
  )
};

export default LayoutPrint;