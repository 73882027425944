import React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import env from '../../config';

function Visualizar(props) {
  const backendUrl = env.backendUrl;

  const handleClick = () => {
    const projeto_id = props.data[0].row.id;
    const mes = props.data[1];
    const newTabUrl = backendUrl + `/visualizar?projeto_id=${projeto_id}&mes=${mes}`;
    
    // Abre uma nova aba
    window.open(newTabUrl, '_blank');
  };

  return (
    <IconButton onClick={handleClick}>
      <VisibilityIcon color="primary" />
    </IconButton>
  );
}

export default Visualizar;
